import $ from 'jquery'
import { SlideMenu } from '@grubersjoe/slide-menu'

// import 'bootstrap' // brings everything, but nothing can be directly used
// import * as bootstrap from 'bootstrap' // brings everything, each part can be directly used as bootstrap.Dropdown etc
// import { Dropdown } from 'bootstrap' // brings only Dropdown (depending on treeshaking in parcel, might still package all the rest in dist), and Dropdown can be directly used

// $(document).ready(() => console.log('ready'))

// mobile- navigation
document.addEventListener('DOMContentLoaded', function () {
  const menuElement = document.getElementById('mobile-navigation')
  const menu = new window.SlideMenu(menuElement)

  menuElement.addEventListener('sm.open', function () {
    $('.menu-link').toggleClass('is-active')
  })

  menuElement.addEventListener('sm.close', function () {
    $('.menu-link').toggleClass('is-active')
  })

  window.addEventListener('resize', (event) => {
    if (event.target.innerWidth >= 768) {
      menu.close()
      $('.menu-link').removeClass('is-active')
    }
  })
})

$(document).ready(() => {
  var topHeight = $('.Header').innerHeight()
  var footerHeight = $('.Copyright').innerHeight()
  var minHeight = window.innerHeight - footerHeight - topHeight

  console.log()
  $('.Login').css('min-height', `${minHeight}px`)
})
